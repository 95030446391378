export const carveWave=(peakList,canvaswaveId,ctx,multiple)=>{

    ctx.clearRect(0, -70, canvaswaveId.width, canvaswaveId.height)
    
    draw(peakList,ctx,canvaswaveId,multiple)
}
const draw = (peakList,ctx,canvaswaveId,multiple) => {
    // requestAnimationFrame(draw);
    // analyser.getByteTimeDomainData(dataArray);
 
    // ctx.clearRect(0, 0, canvaswaveId.width, canvaswaveId.height);
    ctx.lineWidth = 1;
    ctx.strokeStyle = '#fff';
    ctx.strokeStyle="#6B6B6B";
    ctx.beginPath();
    // console.log('宽度'+canvaswaveId.height)
    
    const sliceWidth = canvaswaveId.width * 1.0;
    
 
    for (let i = 1; i <= peakList.length; i++) {
      const v = peakList[i]/1000000 *multiple ;
      const y = v * (canvaswaveId.height/2);
      let x=(i-1)/2
      ctx.moveTo(x, 0);
      ctx.lineTo(x, y);
      ctx.fillStyle = "#fff";
    
    }
 
    
    ctx.stroke();
    ctx.lineTo(0,0);
    ctx.lineTo(70,0);
    ctx.stroke();


  };
